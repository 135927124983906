import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import router from './router'
import store from './store'
import axios from 'axios'
import 'amfe-flexible';
import '../src/assets/css/index.css'
import '../src/assets/css/fonts.css'
import Vant from 'vant';
import 'vant/lib/index.css';
import 'vue-fullpage/vue-fullpage.css'
import VueFullPage from 'vue-fullpage.js'
import {post,fetch,methods,put,del} from './assets/js/axios.js'
import video from 'video.js'

Vue.prototype.axios=axios;
Vue.prototype.$video = video
Vue.prototype.$post=post;
Vue.prototype.$fetch=fetch;
Vue.prototype.$methods=methods;
Vue.prototype.$put=put;
Vue.prototype.$del=del;
Vue.use(VueFullPage)
Vue.use(Vant);
Vue.use(Vuex);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
