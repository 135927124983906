import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    indexFlag:1,
    loginFlag:false,
  },
  mutations: {
    indexFlag(state,msg){
      state.indexFlag=msg;
      localStorage.setItem('indexFlag',msg)
    },
    loginFlag(state,msg){
      state.loginFlag=msg;
      localStorage.setItem('loginFlag',msg)
    },
  },
  getters: {
    getindexFlag(state){
      if(localStorage.getItem('indexFlag')){
        state.indexFlag=localStorage.getItem('indexFlag')
      }
      return state.indexFlag
    },
    getloginFlag(state){
      if(localStorage.getItem('loginFlag')){
        state.loginFlag=localStorage.getItem('loginFlag')
      }
      return state.loginFlag
    },
  },
  actions: {
  },
  modules: {
  }
})
