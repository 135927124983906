import axios from 'axios';
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;


/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function fetch(url,params={}){
  return new Promise((resolve,reject) => {
    axios({
      url: url,
      method: 'get',
      params: params,
    }).then(response => {
        resolve(response);
        //登录失效
        // if(response.data.status==41003){
        //   window.location.href=this.$baseurl
        // }
      })
      .catch(err => {
        reject(err)
      })
  })
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url,data={}){
  return new Promise((resolve,reject) => {
    axios({
      url: url,
      method: 'post',
      data: data,
      headers: { 'authorization': sessionStorage.getItem("token") },
    }).then((response) => {
      resolve(response);
      //登录失效
      if(response.data.status==41003){
        window.location.href=this.$baseurl
      }

    },err => {
      reject(err)
    })
  })
}



export function put(url,data={}){
  return new Promise((resolve,reject) => {
    axios({
      url: url,
      method: 'put',
      data: data,
      headers: { 'authorization': sessionStorage.getItem("token") },
    }).then((response) => {
      resolve(response);
      //登录失效
      if(response.data.status==41003){
        window.location.href=this.$baseurl
      }

    },err => {
      reject(err)
    })
  })
}

export function del(url,data={}){
  return new Promise((resolve,reject) => {
    axios({
      url: url,
      method: 'delete',
      data: data,
      headers: { 'authorization': sessionStorage.getItem("token") },
    }).then((response) => {
      resolve(response);
      //登录失效
      if(response.data.status==41003){
        window.location.href=this.$baseurl
      }

    },err => {
      reject(err)
    })
  })
}

/**
 * 封装两种请求方式的请求
 * url
 * data
 *  接口请求的方式 post get 这两种方式
 */

export function methods(url,data={}) {
  return new Promise((resolve,reject)=>{
    axios({
      url: url,
      method: 'post',
      data: data,
      responseType: 'blob',
      headers: { 'authorization': sessionStorage.getItem("token") },
    }).then((response) => {
      resolve(response);
      //登录失效
      if(response.data.status==41003){
        window.location.href=this.$baseurl
      }

    },err => {
      reject(err)
    })
  })
}


