import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../components/layout'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'layout',
        component: Layout,
        redirect: "/contentPage",
        children: [
            {
                path: '/contentPage',
                name: 'contentPage',
                component: () => import(/* webpackChunkName: "about" */ '../components/contentPage.vue'),
                redirect: "/index",
                children: [
                    {
                        path: '/index',
                        name: 'index',
                        component: () => import(/* webpackChunkName: "about" */ '../views/shouye/index'),
                    },
                    {
                        path: '/about',
                        name: 'about',
                        component: () => import(/* webpackChunkName: "about" */ '../views/about/index'),
                    },
                    {
                        path: '/gengduozhichi',
                        name: 'gengduozhichi',
                        component: () => import(/* webpackChunkName: "about" */ '../views/gengduozhichi/index'),
                    },
                    {
                        path: '/hangye',
                        name: 'hangye',
                        component: () => import(/* webpackChunkName: "about" */ '../views/hangye/index'),
                    },
                    {
                        path: '/chanpin',
                        name: 'chanpin',
                        component: () => import(/* webpackChunkName: "about" */ '../views/chanpin/index'),
                    },
                    {
                        path: '/news',
                        name: 'news',
                        component: () => import(/* webpackChunkName: "about" */ '../views/news/index'),
                    },
                    {
                        path: '/sirendingzhi',
                        name: 'sirendingzhi',
                        component: () => import(/* webpackChunkName: "about" */ '../views/chanpin/sirendingzhi'),
                    },
                    {
                        path: '/anzhuangzhinan',
                        name: 'anzhuangzhinan',
                        component: () => import(/* webpackChunkName: "about" */ '../views/gengduozhichi/anzhuangzhinan'),
                    }, {
                        path: '/jingyingtuandui',
                        name: 'jingyingtuandui',
                        component: () => import(/* webpackChunkName: "about" */ '../views/shouye/jingyingtuandui'),
                    },
                    {
                        path: '/guojirenzheng',
                        name: 'guojirenzheng',
                        component: () => import(/* webpackChunkName: "about" */ '../views/shouye/guojirenzheng'),
                    },
                    {
                        path: '/shiyanzhongxin',
                        name: 'shiyanzhongxin',
                        component: () => import(/* webpackChunkName: "about" */ '../views/shouye/shiyanzhongxin'),
                    },
                    {
                        path: '/mingxingchanpin',
                        name: 'mingxingchanpin',
                        component: () => import(/* webpackChunkName: "about" */ '../views/shouye/mingxingchanpin'),
                    },
                    {
                        path: '/zhanhuixiangqing',
                        name: 'zhanhuixiangqing',
                        component: () => import(/* webpackChunkName: "about" */ '../views/news/zhanhuixiangqing'),
                    },{
                        path: '/addus',
                        name: 'addus',
                        component: () => import(/* webpackChunkName: "about" */ '../views/about/addus'),
                    },
                ]
            },
            {
                path: '/menupage',
                name: 'menupage',
                component: () => import('../views/other/menupage')
            },
            {
                path: '/contact',
                name: 'contact',
                component: () => import('../views/other/contact')
            }

        ]
    },
]

const router = new VueRouter({
    routes
})

export default router
